<template>
  <div>
    <div class="DCIInputForm">
      <div class="container">
        <div class="DCITitle">DCI申领</div>
        <div class="inputMenu">
          <div :class="{active:menuIndex==1}">1.上传作品</div>
          <div :class="{active:menuIndex==2}">2.填写作品信息</div>
        </div>
        <el-form class="form" ref="form" :model="form" :rules="rules" label-width="80px">
          <div v-if="menuIndex==1">
            <el-form-item class="is-required" label="申领类型" prop="applyType">
              <el-radio-group v-model="applyType">
                  <el-radio :label="1">文字作品</el-radio>
                  <!-- <el-radio :label="2">一般作品申领</el-radio>
                  <el-radio :label="3">系列图片作品申领</el-radio> -->
                </el-radio-group>
            </el-form-item>
            <el-form-item label="上传文件" ref="uploader" prop="items" class="uploader is-required">
              <el-upload
                class="commonUpload"
                drag
                :action="uploadUrl"
                :headers="uploadHeaders"
                :on-success="uploadSuccess"
                :on-remove="(file, fileList) => {handleRemove(file, fileList)}"
                :before-upload="uploadBefore"
                multiple
                :limit = '1' 
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <div class="commonUploadInner">
                  <i class="iconfont iconUpload">&#xe606;</i>
                  <div class="el-upload__text">
                      <div>将文件拖到此处，或<em>点击上传</em></div>
                      <div>仅支持上传PDF格式 ，请确认后再提交，文档大小不超过10M<br>论文命名格式：作者_标题 例：张三_论文标题</div>
                      <div>作品需符合《中华人民共和国著作权法》规定的作品，具有一定独创性，文件清晰可见。</div>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
            <div class="tips"><i class="iconfont iconUpload">&#xe714;</i><p>提交申领DCI的作品应为原创作品，若经审核发现为未经授权的恶意盗用、抄袭他人作品，需要承担相应的责任并接受惩罚。</p></div>
            <el-form-item class="submitRow">
              <el-button class="submit" type="submit" @click="toNext()">下一步</el-button>
              <el-button class="cancel" @click="cancel('form')">取消</el-button>
            </el-form-item>
          </div>
          
          <div v-if="menuIndex==2">
            <el-form-item label="作品类型" class="is-required">
              <div class="applyTypeText"><p>文字作品</p>
                <el-tooltip class="item" effect="dark" content="算法推荐作品类型: 文字作品。请你结合作品的表现形式及创作主张，选择与之匹配的类型确认，若作品类型错误有可能面临DCI申领被撤销的风险。" placement="top-start">
                  <span>算法推荐作品类型: 文字作品。请你结合作品的表现形式及创作主张，选择与之匹配的类型确认，若作品类型错误有可能</span>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-tooltip class="item" effect="dark" content="指小说、诗词、散文、论文等以文字形式表现的作品。 文字作品的特点是利用了语言文字本身或内部的特定含义来表达作品的独创性内容，它区别于通过外形来表达作品内容，如 书法作品。 作品形式：小说、诗歌、散文、剧本、论文、专著、教材、编辑作品、书信、译文、译著等。" placement="top-start">
              <div class="applyTypeBox">
                <i class="iconfont iconUpload">&#xebe6;</i><p>文字作品</p>
                <span>指小说、诗词、散文、论文等以文字形式表现的作品。 文字作品的特点是利用了语言文字本身或内部的特定含义来表达作品的独创性内容，它区别于通过外形来表达作品内容…</span>
              </div>
            </el-tooltip>            
            <a-row style="margin-top: 22px;">
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="作者姓名" prop="authorName" class="is-required">
                  <el-input placeholder="请输入作者姓名" v-model="form.authorName" disabled />
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="18" :xl="18">
                <el-form-item label="作品名称" prop="workName">
                  <el-input placeholder="请输入作品名称" v-model="form.workName" />
                  <p class="titleTips">请认真核对，若你的作品样本中包含“作品名称”，此处填写的作品名称须保持一致</p>
                </el-form-item>
              </a-col>
            </a-row>
            <a-row style="border-bottom: 1px solid #ECEFF6;">
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="创作完成时间" prop="creationCompletionDate">
                  <el-date-picker
                      v-model="form.creationCompletionDate"
                      placeholder="选择日期时间"
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptionsStart"
                  >
                  </el-date-picker>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="创作地点" prop="creationCompletionLocation">
                  <china-area-data :areaData="areaData1" @change="selectArea"></china-area-data>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="首次发表日期" prop="publicationDate">
                  <el-date-picker
                      v-model="form.publicationDate"
                      placeholder="选择日期时间"
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptionsEnd"
                  >
            </el-date-picker>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="发表地点" prop="publicationLocation">
                  <china-area-data :areaData="areaData2" @change="selectArea2"></china-area-data>
                </el-form-item>
              </a-col>
              <!-- <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="申领人联系电话" prop="phone">
                  <el-input placeholder="请输入申领人联系电话" v-model="form.phone" />
                </el-form-item>
              </a-col> -->
            </a-row>
            <a-row style="border-bottom: 1px solid #ECEFF6;margin-top: 22px;margin-bottom: 22px;">
              <div class="botTitle">以下内容无需编辑，由系统自动录入</div>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="著作权人" prop="authorSignature" class="is-required">
                  <el-input v-model="form.authorSignature" disabled />
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="发表状态" prop="publicationStatus" class="is-required">
                  <el-input value="已发表" disabled />
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="权利取得方式" prop="rightObtainWay" class="is-required">
                  <el-input value="原始" disabled />
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="创作性质" prop="creationNature" class="is-required">
                  <el-input value="原创" disabled />
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="权利范围" prop="rightScope" class="is-required">
                  <el-input value="全部" disabled />
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="6" :xl="6">
                <el-form-item label="权利归属方式" prop="ownershipWay" class="is-required">
                  <el-input value="个人作品" disabled />
                </el-form-item>
              </a-col>
            </a-row>
            
            <el-form-item class="amountBox">
              <div class="amount">应付金额：<p>¥ {{form.orderAmount}}</p></div>
            </el-form-item>
            <el-form-item label="支付方式" prop="paymode" class="choosePayStyle is-required">
                <el-radio-group v-model="form.paymode">
                <!-- <el-radio label="vip"> <span class="onlyText">中国知链会员权益支付（当前存证次数可用{{vipInfo?vipInfo.existCount:0}}次）</span> </el-radio> -->
                  <el-radio label="wechat">  <i class="iconfont weChatColor"> &#xe678; </i> <span>微信支付</span></el-radio>
                  <el-radio label="alipay"> <i class="iconfont aliPayColor"> &#xe675; </i> <span>支付宝支付</span></el-radio>
              </el-radio-group>
              <!-- <router-link class="buyVip" :to="{path:'/Vip'}">购买会员</router-link> -->
            </el-form-item>
            <el-form-item prop="agreement" style="margin-bottom: 30px;" class="agreement">
              <el-checkbox-group v-model="agreement" >
                <el-checkbox>我已阅读并同意《用户协议》</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item class="submitRow">
              <el-button class="back" type="submit" @click="menuIndex = 1">上一步</el-button>
              <el-button class="submit" type="submit" @click="onSubmit">立即申领</el-button>
              <!-- <el-button class="cancel" @click="cancel('form')">取消</el-button> -->
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>

    <pay-dialog ref="payDialog" payForm="DCI" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as auth from '../../utils/auth'
import ChinaAreaData from "@/components/ChinaAreaData";  // 选择地点插件
import {applyDCIOrder} from "@/api/EvidenceService";
import { queryObligeeList } from "@/api/ObligeeService";
import PayDialog from "../../components/PayDialog";
export default {
  components: {ChinaAreaData,PayDialog},
  data () {
    return {
      menuIndex: 1,
      applyType: 1,

      // /v1/evidence/dci/file/upload  上传文件接口 path=dci/work/file/
      uploadUrl:`${process.env.VUE_APP_BASEURL}/v1/evidence/dci/file/upload?path=dci/work/file/`,
      uploadHeaders:{ 
        'Authorization': this.$store.getters.token,
        'Product-Mode': "READ",
        'Terminal-Code': "IPCHAIN2"+ this.$store.getters.terminalCode,
        'Terminal-Type': "WEB",
        'Merchant-Id': "5",
        'Platform-Subject':  auth.getMid(),
      },
      
      fileList: [],
      form: {
        workName: "", //"浅谈档案管理工作与信息化建设的互动关系",
        workCategory: "WORD", //"",
        creationCompletionDate: "", //"2023-10-17",
        creationCompletionLocation: {"province":"","city":"","cityCode":""}, //{"province":"湖北省","city":"武汉市","cityCode":"420100"},
        copyrightOwnerCodes: [], //["900-8341355-331787"],
        publicationDate: "", //"2023-10-17",
        publicationLocation:{"province":"","city":"","cityCode":""}, //{"province":"湖北省","city":"武汉市","cityCode":"420100"},
        authorName: "", //"郭能宽",
        authorSignature: "", //"郭能宽",
        publicationStatus: "PUBLISHED", //"PUBLISHED",
        rightScope:"ALL",
        creationNature: "ORIGINAL", //"ORIGINAL",
        rightObtainWay:"ORIGINAL",
        ownershipWay:"PERSON",
        paymode:"wechat",
        orderAmount: '5.00',
        items:[],
        phone: "",
      },
      rules: {
        items: [
          { required: true, message: "请上传文件", trigger: "blur" },
        ],
        workName:[
          { required: true, validator: this.valiWorkName, trigger: "blur" },
        ],
        creationCompletionDate:[
          { required: true, message: "请选择创作完成时间", trigger: "blur" },
        ],
        creationCompletionLocation:[
          { required: true, validator: this.valiCity, trigger: "blur" },
        ],
        publicationDate:[
          { required: true, message: "请选择首次发表时间", trigger: "blur" },
        ],
        publicationLocation:[
          { required: true, validator: this.valiCity2, trigger: "blur" },
        ],
        phone:[
          { required: true, validator: this.valiPhoneNum, trigger: "blur" },
        ],
        agreement: [
          { required: true, validator: this.valiAgreement, trigger: "change" },
        ],
      },
      //完成日期在发表日期之前,同时不能超过当前日期
      pickerOptionsStart: {
          disabledDate: time => {
              return time.getTime() > new Date(this.form.publicationDate).getTime() || time.getTime() > Date.now()   //-8.64e7可以让开始时间和结束时间选同一天
          }
      },
      pickerOptionsEnd: {
          disabledDate: time => {
              return time.getTime() < new Date(this.form.creationCompletionDate).getTime()-8.64e7 || time.getTime() > Date.now()     //-8.64e7可以让开始时间和结束时间选同一天
          }
      },

      agreement: true,
    }
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo","authInfo"]),
  },
  created() {
    // 地点规则，以及添加默认地点
    this.areaData1 = {
      province: this.form.creationCompletionLocation.province,
      city: this.form.creationCompletionLocation.city,
    },
    this.areaData2 = {
      province: this.form.publicationLocation.province,
      city: this.form.publicationLocation.city,
    }
    if(!this.form.creationCompletionLocation.province){
      this.form.creationCompletionLocation.province = '湖北省'
    }
    if(!this.form.creationCompletionLocation.city){
      this.form.creationCompletionLocation.city = '武汉市'
      this.form.creationCompletionLocation.cityCode = '420100'
    }
    if(!this.form.publicationLocation.province){
      this.form.publicationLocation.province = '湖北省'
    }
    if(!this.form.publicationLocation.city){
      this.form.publicationLocation.city = '武汉市'
      this.form.publicationLocation.cityCode = '420100'
    }

    // 获取权利人/作者，以及权利人编码
    queryObligeeList(1, 100).then(({ data }) => {
      // console.log('获取权利人相关信息',data);
      if(data.list.length>0){
        // this.form.authorName = data.list[0].userName;
        // this.form.authorSignature = data.list[0].userName;
        if(data.list[0].obligeeType == '1'){
          this.form.authorName = data.list[0].userName;
          this.form.authorSignature = data.list[0].userName;
        }else if(data.list[0].obligeeType == '2'){
          this.form.authorName = data.list[0].companyName;
          this.form.authorSignature = data.list[0].companyName;
        }
        this.form.copyrightOwnerCodes.push(data.list[0].obligeeCode);
      }
    });
  },
  methods: {
    uploadBefore(file) {
        const NoPassFileTypes=['.pdf']
        const fileName=file.name
        const fileEnd=fileName.substring(fileName.indexOf("."))
				const isFileTypePass = NoPassFileTypes.includes(fileEnd)
				const isLt500M = file.size / 1024 / 1024 < 10;

				if (!isFileTypePass) {
					this.$message.error('仅支持上传 pdf 格式文件');
				}
				if (!isLt500M) {
					this.$message.error('上传文件大小不能超过 10MB!');
				}
				return isFileTypePass&&isLt500M;
			},
    uploadSuccess(response, file, fileList){
        console.log(arguments)
        if(response != ''){
          this.$refs.uploader.clearValidate()
          this.form.items.push(response);
          // this.form.recordCode = response.recordCode
          if(response){
            //计算价格
            this.calAmount();
          }
        }
    },
    removeFile(file,fileList){
      let that=this;
      let response=file.response;
      if(response){
        let index=that.form.items.findIndex(t=>{
          return t.sourceCode==response.sourceCode;
        });
        if(index>-1){
          that.form.items.splice(index,1);
        }

      }
      console.log(file,fileList);
      // 更新价格
      this.calAmount();
    },
    handleRemove(file,fileList){
      let that=this;
      for(let i=0;i<this.form.items.length;i++){
        if(file.response.sourceName == this.form.items[i].sourceName){
          // console.log("i-------",i);
          that.form.items.splice(i,1);
          // 更新价格
          this.calAmount();
        }
      }
    },
    handleExceed(){  //超过上传文件数量限制
      this.$message.error('只能上传一个文件');
    },
    // 计算价格
    calAmount() {
      
    },

    // 下一步
    async toNext(){
      try {
        const formBaseResult = await this.$refs["form"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        // this.mixinsScrollView();
        return;
      }
      this.menuIndex = 2;
    },
    // 点击取消按钮，清空输入框
    cancel(formName){
        this.$refs[formName].resetFields();//点击取消按钮，清空el-input
    },

    // 验证作品名称和上传文件名称一致
    valiWorkName(rule, value, callback){
      if(this.form.workName == ""){
        callback(new Error("请输入作品名称"));
        return;
      }
      if(this.form.workName != this.form.items[0].sourceName){
        callback(new Error("作品名称须与作品样本文件名称保持一致"));
        return;
      }
      if(this.form.workName.length > 50){
        callback(new Error("作品名称不能超过50字"));
        return;
      }
      callback();
    },
    // 自定义选择地点验证
    valiCity(rule, value, callback) {
      if (!this.form.creationCompletionLocation.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.creationCompletionLocation.city) {
        callback(new Error("请选择城市"));
        return;
      }
      // if (!this.form.area) {
      //   callback(new Error("请选择区县"));
      //   return;
      // }
      callback();
    },
    valiCity2(rule, value, callback) {
      if (!this.form.publicationLocation.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.publicationLocation.city) {
        callback(new Error("请选择城市"));
        return;
      }
      callback();
    },
    selectArea(data) {
      // console.log(data);
      if(data.laber == 'province'){
        this.form.creationCompletionLocation.province = data.value;
        this.form.creationCompletionLocation.city = '';
      }else if(data.laber == 'city'){
        this.form.creationCompletionLocation.city = data.value;
        this.form.creationCompletionLocation.cityCode = data.selectData.city.value;
      }

      // console.log(this.form.province);
      // this.form.area = data.selectData.area.name;
    },
    selectArea2(data) {
      if(data.laber == 'province'){
        this.form.publicationLocation.province = data.value;
        this.form.publicationLocation.city = '';
      }else if(data.laber == 'city'){
        this.form.publicationLocation.city = data.value;
        this.form.publicationLocation.cityCode = data.selectData.city.value;
      }
      // this.form.area2 = data.selectData.area.name;
    },
    // 手机号码校验函数
    valiPhoneNum(rule, value, callback) {
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号码'));
      }
    },
    // 自定义验证同意协议
    valiAgreement(rule, value, callback){
      if (!this.agreement) {
        callback(new Error("请勾选同意协议"));
        return;
      }
      callback();
    },
    //  提交表单
    async onSubmit(){
        try {
          const formBaseResult = await this.$refs["form"].validate();
          //其他验证
          // if(!this.agreement){
          //   this.$message.error('请勾选同意协议');
          //   return false;
          // }
        } catch (error) {
          // 有错误,定位到错误
          // eslint-disable-next-line
          console.error(error);
          // this.mixinsScrollView();
          return;
        }
        if (this.Info) { // 如果已经提交过订单了，就阻止重复提交
          this.$refs.payDialog.showDialog(this.Info);
          this.isPayDialogVisible = true;
          return false;
        }
       // 验证成功，提交表单
      //  console.log(this.form);
       applyDCIOrder(this.form).then(({ data }) => {
        // that.order = data;
        // console.log("check::::::::::::",data);
        this.Info = data;
        // if (data.paymentStatus == "21") {   // 21是已经支付成功
        if (data.orderStatus == "12") {   // 12是已经支付成功，正在检测中
          this.bus.$emit("refreshVip");
          this.onSetPayDialogClose(true);
        } else {
          this.$refs.payDialog.showDialog(data);
          this.isPayDialogVisible = true;
        }
      });

    },
    onSetPayDialogClose(result) {
      console.log('支付成功result:',result);
      // 如果支付成功，跳转到详情页面
      if (result) {
        this.$router.push({
          path: "/Mine/MyDCI",
          // query: { orderNo: this.Info.orderNo },
        });
      }
    },


  },
}
</script>

<style lang="scss" scoped>
/deep/{
  .el-radio__label,.el-checkbox__label{color: rgba(0,0,0,0.65)!important;}
  .contentTextarea textarea{height: 200px;}
  .el-upload-dragger{height: 250px;}
  .el-upload{display: block;}
  .el-form-item__label{text-align: left;}
  .el-date-editor.el-input{width: auto;}
}
.DCIInputForm{
  .container{
    background: #ffffff;border-radius: 4px;padding: 40px;margin-top: 20px;margin-bottom: 50px;box-shadow: 0px 0px 10px 0px rgba(2,37,116,0.08);
    .DCITitle{
      font-size: 24px;
      font-family: 'SourceHanSansCN, SourceHanSansCN';
      font-weight: 500;
      color: #333333;
      line-height: 36px;
      margin-bottom: 30px;
      padding-left: 16px;
      position: relative;
    }
    .DCITitle::before{
      content: '';
      width: 4px;
      height: 24px;
      background: #007DFF;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 2px;
    }
    .inputMenu{
      padding: 0 140px;
      margin: 0 0 30px 0;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div{
        width: calc(50% - 20px);
        height: 48px;
        line-height: 48px;
        background: #ECEFF6;
        font-size: 20px;
        font-family: 'SourceHanSansCN, SourceHanSansCN';
        color: #333333;
        text-align: center;
        position: relative;
      }
      div:nth-child(1){
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }
      div:nth-child(1)::after{
        content: '';width: 0;height: 0;border-left: 24px solid #ECEFF6;border-top: 24px solid transparent;border-bottom: 24px solid transparent;position: absolute;top: 0;right: -24px;
      }
      div:nth-child(1).active::after{
        content: '';width: 0;height: 0;border-left: 24px solid #316EF4;border-top: 24px solid transparent;border-bottom: 24px solid transparent;position: absolute;top: 0;right: -24px;
      }
      div:nth-child(2){
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
      div:nth-child(2)::before{
        content: '';width: 0;height: 0;border-top: 24px solid #ECEFF6;border-left: 24px solid transparent;border-bottom: 24px solid transparent;position: absolute;top: 0;left: -24px;
      }
      div:nth-child(2)::after{
        content: '';width: 0;height: 0;border-bottom: 24px solid #ECEFF6;border-left: 24px solid transparent;border-top: 24px solid transparent;position: absolute;top: 0;left: -24px;
      }
      div:nth-child(2).active::before{
        content: '';width: 0;height: 0;border-top: 24px solid #316EF4;border-left: 24px solid transparent;border-bottom: 24px solid transparent;position: absolute;top: 0;left: -24px;
      }
      div:nth-child(2).active::after{
        content: '';width: 0;height: 0;border-bottom: 24px solid #316EF4;border-left: 24px solid transparent;border-top: 24px solid transparent;position: absolute;top: 0;left: -24px;
      }
      div.active{
        background: #316EF4;
        color: #FFFFFF;
      }
    }
    .form{padding: 0 140px;
      .el-upload__text div:nth-child(2),.el-upload__text div:nth-child(3){font-size: 14px;font-family: 'PingFangSC-Regular, PingFang SC';color: rgba(0,0,0,0.45);line-height: 22px;}
      .el-upload__text div:nth-child(3){color:rgba(0,0,0,0.65);margin-top: 10px;}
      /deep/.el-radio-group,.el-radio{display: flex;align-items: center;height: 40px;line-height: 40px;}
      /deep/.el-radio__label{display: flex;align-items: center;height: 40px;line-height: 40px;}
      /deep/.el-checkbox-group{line-height: 20px;}
      .amount{display: flex;align-items: center;font-size: 14px;font-weight: 500;color: #000000;margin-bottom: -20px;
        p{color: #316EF4;font-size: 22px;}
      }
      .aliPayColor{font-size: 28px;margin-right: 5px;}
      .weChatColor{font-size: 24px;margin-right: 7px;}
      
      .uploader /deep/.el-form-item__content,.submitRow /deep/.el-form-item__content{
        margin-left: 0!important;
      }
      .tips{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        i{color: #D48806;font-size: 18px;margin-right: 6px;font-weight: bold;}
        p{font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';color: #D48806;line-height: 24px;}
      }
      .submitRow button{
        width: 120px;height: 40px;line-height: 38px;background: #ECEFF6;border-radius: 4px;font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;color: #333333;padding: 0;border: none;outline: none;
      }
      .submitRow button:hover{border: none;outline: none;}
      .submitRow button.submit{background: #316EF4;color: #FFFFFF;margin-right: 20px;}
      .submitRow button.back{margin-right: 20px;}

      // 第二步
      .applyTypeText{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
        p{font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;color: #316EF4;line-height: 40px;display: inline-block;margin-right: 10px;}
        span{font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';color: #333333;line-height: 40px;}
      }
      .applyTypeBox{
        width: 270px;background: #FAFBFC;border-radius: 4px;border: 1px solid #316EF4;padding: 20px;position: relative;
        i{position: absolute;top: 0;right: 0;z-index: 1;font-size: 14px;color: #FFFFFF;line-height: 14px;}
        p{font-size: 16px;font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;color: #333333;line-height: 24px;margin-bottom: 10px;}
        span{font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 400;color: rgba(0,0,0,0.42);line-height: 22px;}
      }
      .linkage{width: 100%;}
      /deep/.linkage div{width: 48%;}
      /deep/.linkage div .el-select{width: 100%;}
      /deep/.linkage div .el-select .el-input{width: 100%;}
      .applyTypeBox::after{content: '';width: 0;height: 0;border-top: 24px solid #316EF4;border-left: 24px solid transparent;border-bottom: 24px solid transparent;position: absolute;top: 0;right: 0;}
      .ant-row /deep/.el-form-item__content{margin-left: 0!important;}
      .ant-row /deep/.el-form-item__label{white-space: nowrap;}
      .ant-row /deep/.el-form-item__content{margin-right: 20px;}
      .titleTips{color: rgba(0,0,0,0.45);font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';line-height: 32px;}
      .botTitle{font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;color: #333333;line-height: 21px;margin-bottom: 10px;}
      .amountBox /deep/.el-form-item__content,.agreement /deep/.el-form-item__content{margin-left: 0!important;}
    }
  }
}

@media screen and (max-width: 991px){
  .DCIInputForm .container{padding: 20px;}
  .DCIInputForm .container .inputMenu,.DCIInputForm .container .form{padding: 0;}
}
</style>
